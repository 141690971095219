import React, { useState } from 'react';
import { Button, Card, CardActionArea, CardContent, FormControlLabel, ListItemText, Typography } from '@mui/material';
import useIsMobile from '../../utils/useIsMobile';
import { motion } from 'framer-motion';
import { Checkbox } from 'primereact/checkbox';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import useIsLarge from '../../utils/useIsLarge';
import { useUser } from '../../context/UserContext';

const CommandItem = ({commandObj = {}, backgroundColor = '#5D3B9E', cardStyle = {}, openDialogComponent = () => {}, userSettings = {}, update = () => {}, deletekey = () => {}}) => {
    const [isHovered, setIsHovered] = useState(false);
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const isLarge = useIsLarge();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const hoverAnimation = {
        scale: [1, 1.025, 1],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.5, 1]
        }
    };
    const clickAnimation = {
        scale: [1, 0.95, 1],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.5, 1]
        }
    };
    const permissionDictionary = {
        'create announcement': ['moderator:manage:announcements'],
        'create clip': ['clips:edit'],
        'create shoutout': ['moderator:manage:shoutouts'],
        'snooze next ad': ['channel:manage:ads'],
        'start commercial': ['channel:edit:commercial'],
        'update category': ['channel:manage:broadcast'],
        'update title': ['channel:manage:broadcast']
    };

    const deleteHandler = (e) => {
        e.stopPropagation();
        deletekey(['customCommands', commandObj['title']]);
    };
    const toggleDisableCommand = (e, enable) => {
        e.stopPropagation();
        if (enable) {
            update({disabledCommands: [...userSettings['disabledCommands'].filter((cmd) => cmd != commandObj['title'])]});
        } else {
            update({disabledCommands: [...userSettings['disabledCommands'], commandObj['title']]});
        }
    };
    const toggleIsAdminCommand = (e, enable) => {
        e.stopPropagation();
        if (enable) {
            console.log({...userSettings['permissions'], adminCommands: [...userSettings['permissions']['adminCommands'], commandObj['title']]});
            update({permissions: {...userSettings['permissions'], adminCommands: [...userSettings['permissions']['adminCommands'], commandObj['title']]}});
        } else {
            console.log({permissions: {...userSettings['permissions'], adminCommands: userSettings['permissions']['adminCommands'].filter((cmd) => cmd != commandObj['title'])}});
            update({permissions: {...userSettings['permissions'], adminCommands: userSettings['permissions']['adminCommands'].filter((cmd) => cmd != commandObj['title'])}});
        }
    };
    const checkIfAdminCommand = (command) => {
        if ('permissions' in userSettings === false) {
            update({permissions: {}});
            return false;
        }
        if ('adminCommands' in userSettings['permissions'] === false) {
            update({permissions: {...userSettings['permissions'], adminCommands: []}});
            return false;
        }
        return (userSettings['permissions']['adminCommands'].includes(command));
    };

    const hasPermissionScope = (scope = []) => {
        if ('twitchPermissions' in userSettings === false) return false;
        if (scope.length === 0) return true;
        for (let index in scope) {
            if (userSettings['twitchPermissions'].includes(scope[index])) return true;
        }
        return false;
    };
    const missingScopes = (commandObj = {}) => {
        let returnArr = [];
        for (let actionObj of commandObj['actions']) {
            if (actionObj['type'].toLowerCase() === 'twitch') {
                const action = actionObj['action'].toLowerCase();
                if (!hasPermissionScope(permissionDictionary[action])) {
                    returnArr.push(permissionDictionary[action]);
                }
            } else if (actionObj['type'].toLowerCase() === 'compare' && 'actions' in actionObj) {
                for (let subActionObj of actionObj['actions']) {
                    if (subActionObj['type'].toLowerCase() === 'twitch') {
                        const subAction = subActionObj['action'].toLowerCase();
                        if (!hasPermissionScope(permissionDictionary[subAction])) {
                            returnArr.push(permissionDictionary[subAction]);
                        }
                    }
                }
            }
        }
        return returnArr;
    };
    const addPermission = (e, scopeArr = []) => {
        e.stopPropagation();
        openDialogComponent('addtwitchpermission', {scope: scopeArr});
    };

  return (
    <motion.div whileHover={!isMobile && hoverAnimation} whileTap={!isMobile && clickAnimation} onHoverStart={() => setIsHovered(true)} onHoverEnd={() => setIsHovered(false)}>
        <Card sx={{...cardStyle}}>
            <CardActionArea disableRipple disableTouchRipple sx={{height: cardStyle.height}} onClick={() => openDialogComponent('newcommand', commandObj)}>
                {(isHovered || isMobile) && (
                    <React.Fragment>
                        <CardContent sx={{position: 'absolute', top: -6, right: -6}}>
                            <Button size='medium' variant='text' color='error' onClick={deleteHandler}>Delete</Button>
                        </CardContent>
                    </React.Fragment>
                )}
                {!isMobile && (
                    <React.Fragment>
                        <CardContent sx={{position: 'absolute', bottom: -6, left: -6}}>
                            <FormControlLabel sx={{marginTop: '8px', marginBottom: '8px'}} control={
                                    <Checkbox onChange={(e) => toggleDisableCommand(e, e.checked)} style={{marginRight: '16px', marginLeft: '12px'}}
                                        checked={!userSettings['disabledCommands'].includes(commandObj['title'])} />
                                } label={isHovered ? 'Enabled' : ''} labelPlacement='end' />
                        </CardContent>
                        <CardContent sx={{position: 'absolute', top: -6, left: -6}}>
                                <FormControlLabel sx={{marginTop: '8px', marginBottom: '8px'}} control={
                                        <Checkbox onChange={(e) => toggleIsAdminCommand(e, e.checked)} style={{marginRight: '16px', marginLeft: '12px'}}
                                            checked={checkIfAdminCommand(commandObj['title'])} />
                                    } label={isHovered ? 'Admin Command' : ''} labelPlacement='end' />
                        </CardContent>
                    </React.Fragment>
                )}
                {(missingScopes(commandObj).length > 0) && !authCtx.editor && (
                    <CardContent sx={{position: 'absolute', bottom: -4, right: -8}}>
                        <Button size='medium' variant='text' color='warning' startIcon={<PriorityHighIcon />}
                            onClick={(e) => addPermission(e, missingScopes(commandObj))}>Action Required</Button>
                    </CardContent>
                )}
                <CardContent sx={{textAlign: 'center'}}>
                    <ListItemText primary={`${userSettings['commandDelimiter']}${commandObj['title']}`} />
                    <Typography>{`${commandObj['actions'].length} actions`}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </motion.div>
  );
};

export default CommandItem;