import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import CustomSnackbar from '../../utils/customsnackbar';
import { Checkbox } from 'primereact/checkbox';
import './customVariables.css';
import useCopyArray from '../../utils/useCustomVariableCopyArray';
import { Toast } from 'primereact/toast';
import { FilterMatchMode } from 'primereact/api';
import VariableCopyDropdown from './VariableCopyDropdown';

const CustomVariables = ({ backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, openDialogComponent = () => {}, deletekey = () => {} }) => {
    const [tableData, setTableData] = useState([]);
    const [snackAlert, setSnackAlert] = useState({ "open": false, "severity": "success", "text": "", "autoHideDuration": 5000 });
    const [newVariableType, setNewVariableType] = useState('');
    const [selectedRows, setSelectedRows] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(() => {
        const savedRowsPerPage = localStorage.getItem('customVariablesRPP');
        return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 5;
    });
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const toast = useRef(null);
    const isMobile = useIsMobile();
    const { getCopyArray } = useCopyArray();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const newVariableModel = [
        {
            label: 'Number',
            icon: 'pi pi-sliders-h',
            command: () => setNewVariableType('Number')
        },
        {
            label: 'Text',
            icon: 'pi pi-pencil',
            command: () => setNewVariableType('Text')
        },
        {
            label: 'Boolean',
            icon: 'pi pi-check-circle',
            command: () => setNewVariableType('Boolean')
        },
        {
            label: 'List',
            icon: 'pi pi-list',
            command: () => setNewVariableType('List')
        }
    ];
    
    const copyBodyTemplate = (filterWord) => {
        return (
            <VariableCopyDropdown userSettings={userSettings} setSnackAlert={setSnackAlert} filterWord={filterWord} />
        );
    };
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const deleteHandler = (variable) => {
        deletekey(['customVariables', variable.code]);
    };
    const confirmDelete = (variable) => {
        confirmDialog({
            message: 'Do you want to delete this variable?',
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteHandler(variable),
            reject: () => { }
        });
    };
    const deleteSelectedVariables = () => {
        if (!selectedRows || selectedRows.length === 0) return;
        selectedRows.forEach(variable => deletekey(['customVariables', variable.code]));
        const updatedTableData = tableData.filter(row => !selectedRows.includes(row));
        setTableData(updatedTableData);
        setSelectedRows(null);
    };
    const confirmDeleteSelected = () => {
        confirmDialog({
            message: 'Do you want to delete the selected variables?',
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: deleteSelectedVariables,
            reject: () => { }
        });
    };
    const editCompleteHandler = (e) => {
        const isAlphanumeric = (str) =>
            /^[a-zA-Z0-9 _\-()&+%#/@?!*=']*$/.test(str);
        const { copy, datatype, description, id, title, value, unique } =
            e.rowData;
        let code = e.rowData.code; // Use let to allow reassignment
        const { newValue, field } = e;
    
        if (!isAlphanumeric(newValue)) return;
        if (newValue.length < 1 || newValue.length > 500) return;
        if (field === 'title' && newValue.length > 50) return;
    
        let updatedCustomVariables = { ...userSettings.customVariables };
        let updatedTableData = [...tableData];
        const index = updatedTableData.findIndex((data) => data.id === id);
    
        if (field === 'title') {
            // Generate newCode by replacing spaces with underscores
            let newCode = newValue.replace(/ /g, '_');
            // Sanitize newCode to remove invalid characters
            newCode = newCode.replace(/[^a-zA-Z0-9_\-()&+%#/@?!*=']/g, '');
    
            if (!userSettings.customVariables.hasOwnProperty(newCode)) {
                // Remove the old entry and add the new one
                const updatedVariable = {
                    ...userSettings.customVariables[code],
                    [field]: newValue,
                };
                delete updatedCustomVariables[code];
                updatedCustomVariables[newCode] = updatedVariable;
                code = newCode; // Update the code variable
    
                if (index !== -1) {
                    updatedTableData[index][field] = newValue;
                    updatedTableData[index]['code'] = newCode;
                }
            } else {
                // If the code exists, only update the title
                updatedCustomVariables[code] = {
                    ...userSettings.customVariables[code],
                    [field]: newValue,
                };
                if (index !== -1) {
                    updatedTableData[index][field] = newValue;
                }
            }
        } else {
            // For other fields, update as usual
            updatedCustomVariables[code] = {
                ...userSettings.customVariables[code],
                [field]: newValue,
            };
            if (index !== -1) {
                updatedTableData[index][field] = newValue;
            }
        }
    
        update({ customVariables: updatedCustomVariables });
        setTableData(updatedTableData);
    };    
    const actionBodyTemplate = (variable) => {
        return (
            <React.Fragment>
                <Button variant='contained' color='info' sx={{ marginRight: '8px' }} onClick={() => openDialogComponent('editcustomvariable', { code: variable.code })}>Edit</Button>
                <Button variant='contained' color='error' onClick={() => confirmDelete(variable)}>Delete</Button>
            </React.Fragment>
        );
    };
    const addVariable = () => {
        if (newVariableType === '') return;
        const _tableData = [...tableData];
        const initialValueMap = {
            number: 0,
            text: '',
            boolean: false,
            list: []
        };
        const insertObj = {
            id: tableData.length + 1,
            title: `New Variable #${tableData.length + 1}`,
            description: `My new ${newVariableType.toLowerCase()} variable`,
            datatype: newVariableType.toLowerCase(),
            code: `${newVariableType.toLowerCase()}#${tableData.length + 1}`,
            copy: getCopyArray(`${newVariableType.toLowerCase()}#${tableData.length + 1}`, newVariableType.toLowerCase()),
            value: initialValueMap[newVariableType.toLowerCase()]
        };
        update({ customVariables: { ...userSettings.customVariables, [`${newVariableType.toLowerCase()}#${tableData.length + 1}`]: {
            title: insertObj.title,
            description: insertObj.description,
            datatype: insertObj.datatype,
            value: insertObj.value
        } } }).then((success) => {
            if (success) {
                _tableData.push(insertObj);
                setTableData(_tableData);
            }
        });
    };
    const paginatorLeft = (
        <div className="card flex justify-content-center">
            <SplitButton text label={`Add ${newVariableType} Variable`} severity='success' size='small' icon='pi pi-plus' model={newVariableModel}
                onClick={addVariable} />
        </div>
    );
    const paginatorRight = (
        <Grid container sx={{width: '300px'}}>
            <Grid item xs={6}>
                <Typography sx={{width: '100%', textAlign: 'right', marginTop: '8px'}}>Click on fields to edit</Typography>
            </Grid>
            <Grid item xs={6}>
                {selectedRows && (<div className="mb-2">
                    <Button sx={{float: 'right'}} variant='contained' color='error' onClick={confirmDeleteSelected} disabled={!selectedRows || selectedRows.length === 0}>Delete Selected</Button>
                </div>)}
            </Grid>
        </Grid>
    );
    const valueBodyTemplate = (rowData) => {
        if (rowData.datatype === 'Boolean') {
            return capitalizeFirstLetter(String(rowData.value));
        } else if (rowData.datatype === 'List') {
            if (!rowData.unique) {
                return `${String(rowData.value.length)} items`;
            } else {
                return '';
            }
        } else {
            return rowData.value;
        }
    };    

    useEffect(() => {
        if ('customVariables' in userSettings) {
            const _tableData = Object.keys(userSettings['customVariables']).map((code, index) => {
                const variable = userSettings['customVariables'][code];
                return {
                    id: index + 1,
                    title: variable['title'],
                    description: variable['description'],
                    datatype: capitalizeFirstLetter(variable['datatype']),
                    code,
                    copy: getCopyArray(code, variable['datatype'], variable['unique'] ?? false),
                    value: variable['datatype'] === 'boolean' ? capitalizeFirstLetter(String(variable['value'])) : variable['value'],
                    unique: variable['unique'] ?? false,
                    userValues: variable['userValues'] ?? {}
                };
            });
            setTableData(_tableData);
        }
    }, [userSettings]);

    const handleCheckboxChange = (e, rowData) => {
        confirmDialog({
            message: `Do you want to change this variable to ${e.checked ? 'unique? This will add a unique value for each user.' : 'global? This will clear all unique values stored.'}`,
            header: 'Change Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const updatedTableData = tableData.map(data => {
                    if (data.id === rowData.id) {
                        return { ...data, unique: e.checked, userValues: [] };
                    }
                    return data;
                });
                setTableData(updatedTableData);
                const saveData = { 
                    customVariables: { 
                        ...userSettings.customVariables, 
                        [rowData.code]: { 
                            ...userSettings.customVariables[rowData.code], 
                            unique: e.checked,
                            userValues: {}
                        } 
                    } 
                };
                update(saveData);
            },
            reject: () => {}
        });
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(e.rows);
        localStorage.setItem('customVariablesRPP', e.rows);
    };

    return (
        <Box sx={{padding: '16px'}}>
            <ConfirmDialog />
            <CustomSnackbar open={snackAlert.open} autoHideDuration={snackAlert.autoHideDuration} severity={snackAlert.severity} snackText={snackAlert.text} setSnackAlert={setSnackAlert} />
            <Toast ref={toast} />
            <div className="p-inputgroup mb-3">
                <span className="p-inputgroup-addon" style={{'--background-color': backgroundColor}}>
                    <i className="pi pi-search"></i>
                </span>
                <InputText style={{'--background-color50': colorScheme.background50}} placeholder="Global Search" onInput={(e) => setFilters({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS } })} />
            </div>
            <div>
                <DataTable value={tableData} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} editMode="cell" paginator rows={rowsPerPage} 
                    rowsPerPageOptions={[5, 10, 25, 50]} scrollable scrollHeight={isMobile ? '50vh' : '65vh'} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} 
                    stripedRows selectionMode="checkbox" filters={filters} globalFilterFields={['title', 'description', 'datatype', 'value', 'code']}
                    onPage={handleRowsPerPageChange}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field='id' header='#' style={{ width: '25px' }}></Column>
                    <Column field='title' header='Title' style={{ whiteSpace: 'nowrap' }} editor={(options) => (
                        <InputText style={{ backgroundColor: colorScheme.background25, width: '100%' }}
                            value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
                    )} onCellEditComplete={editCompleteHandler}></Column>
                    <Column field='description' header='Description' style={{ whiteSpace: 'nowrap' }} editor={(options) => (
                        <InputText style={{ backgroundColor: colorScheme.background25, width: '100%' }}
                            value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
                    )} onCellEditComplete={editCompleteHandler}></Column>
                    <Column field='value' header='Value' body={valueBodyTemplate} style={{ whiteSpace: 'nowrap' }}></Column>
                    <Column field='unique' header='Unique' body={(rowData) => <Checkbox checked={rowData['unique']} onChange={(e) => handleCheckboxChange(e, rowData)} />} />
                    <Column field='datatype' header='Type' body={(rowData) => capitalizeFirstLetter(rowData.datatype)} style={{ width: '100px' }}></Column>
                    <Column header='Functions Available' body={(rowData) => copyBodyTemplate(rowData.title.replace(/ /g, '_'))} style={{ width: '400px' }}></Column>
                    <Column header='Actions' body={actionBodyTemplate} style={{ width: '200px' }}></Column>
                </DataTable>
            </div>
        </Box>
    );
};

export default CustomVariables;
